<template>
  <div class="user-data-items animated fadeIn flex flex-wrap justify-start">
    <app-icon-card-vertical
      v-for="item in cards"
      :key="item.id"
      v-bind="item.card"
      class="w-1/2 flex-none mb-64"
    />
  </div>
</template>

<script>
import AppIconCardVertical from '@/components/AppIconCardVertical.vue'

export default {
  name: 'UserDataItems',
  components: {
    AppIconCardVertical,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
}
</script>
